import React, { useState, useEffect } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import AdminLayout from './AdminLayout'; // Import the layout component

const AdminControlPage = () => {
    const [password, setPassword] = useState('');
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        // Check if the password cookie exists and matches the expected password
        const savedPassword = Cookies.get('adminPassword');
        if (savedPassword === 'datavan123') {
            setIsAuthenticated(true);
        }
    }, []);

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const handlePasswordSubmit = (e) => {
        e.preventDefault();
        if (password === 'datavan123') {
            // Save the password to cookies
            Cookies.set('adminPassword', password, { expires: 7 }); // expires in 7 days
            setIsAuthenticated(true);
        } else {
            alert('Incorrect Password!');
        }
    };

    // const handleLogout = () => {
    //     Cookies.remove('adminPassword');
    //     setIsAuthenticated(false);
    //     navigate('/admin'); // Redirect to the login page
    // };

    return (
        <div className="admin-control-page">

            <AdminLayout>
                {/* <h2>管理</h2> */}
                {/* Page content goes here */}
            </AdminLayout>
            <h1 style={{ textAlign: 'center', marginTop: '20px' }}>
                ルームサービス管理ページ
                <br />
                <span style={{ fontSize: '1rem', color: 'grey' }}>Room Services Admin Page</span>
            </h1>
            {!isAuthenticated ? (
                <Form onSubmit={handlePasswordSubmit} style={{ maxWidth: '300px', margin: 'auto', marginTop: '50px' }}>
                    <InputGroup>
                        <Form.Control
                            type="password"
                            value={password}
                            onChange={handlePasswordChange}
                            placeholder="パスワードを入力"
                            required
                        />
                        <Button type="submit">送信</Button>
                    </InputGroup>
                </Form>
            ) : (
                <div className="links" style={{ textAlign: 'center', marginTop: '50px' }}>
                    <h3>
                        内容管理ページ
                        <br />
                        <span style={{ fontSize: '1rem', color: 'grey' }}>Content Management Page</span>
                    </h3>
                    <Button onClick={() => navigate('/wifi')} style={{ margin: '10px', fontSize: '1.5rem', padding: '1rem 2rem' }}>
                        <div style={{ lineHeight: '1.2' }}>
                            <span>WiFi管理</span><br />
                            <span style={{ fontSize: '1rem' }}>WiFi Management</span>
                        </div>
                    </Button>
                    <Button onClick={() => navigate('/services')} style={{ margin: '10px', fontSize: '1.5rem', padding: '1rem 2rem' }}>
                        <div style={{ lineHeight: '1.2' }}>
                            <span>サービス管理</span><br />
                            <span style={{ fontSize: '1rem' }}>Service Management</span>
                        </div>
                    </Button>
                    <Button onClick={() => navigate('/questions')} style={{ margin: '10px', fontSize: '1.5rem', padding: '1rem 2rem' }}>
                        <div style={{ lineHeight: '1.2' }}>
                            <span>質問管理</span><br />
                            <span style={{ fontSize: '1rem' }}>Question Management</span>
                        </div>
                    </Button>

                    <p style={{ fontSize: '0.75rem', color: 'grey', marginTop: '10px' }}>
                        質問管理は外部ページにリダイレクトされます。ユーザーIDは「default」、パスワードはこの管理ページと同じです。
                    </p>

                    <h3 style={{ marginTop: '20px' }}>
                        レポート
                        <br />
                        <span style={{ fontSize: '1rem', color: 'grey' }}>Reports</span>
                    </h3>
                    <Button onClick={() => navigate('/servicereport')} style={{ margin: '10px', fontSize: '1.5rem', padding: '1rem 2rem' }}>
                        <div style={{ lineHeight: '1.2' }}>
                            <span>サービスレポート</span><br />
                            <span style={{ fontSize: '1rem' }}>Service Report</span>
                        </div>
                    </Button>
                    <Button onClick={() => navigate('/billreport')} style={{ margin: '10px', fontSize: '1.5rem', padding: '1rem 2rem' }}>
                        <div style={{ lineHeight: '1.2' }}>
                            <span>請求書レポート</span><br />
                            <span style={{ fontSize: '1rem' }}>Bill Report</span>
                        </div>
                    </Button>
{/* 
                    <div style={{ marginTop: '30px' }}>
                        <Button
                            onClick={handleLogout}
                            style={{ backgroundColor: 'red', color: 'white', fontSize: '1.5rem', padding: '1rem 2rem' }}
                        >
                            ログアウト<br />
                            <span style={{ fontSize: '1rem' }}>Log Out</span>
                        </Button>
                    </div> */}
                </div>
            )}
        </div>
    );
};

export default AdminControlPage;