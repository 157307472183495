import React, { useState, useEffect } from 'react';
import Papa from 'papaparse';
import { Button, Table } from 'react-bootstrap';
import config from './config';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import AdminLayout from './AdminLayout'; // Import the layout component

const QA = () => {
    const [csvData, setCsvData] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const [errors, setErrors] = useState([]);
    const [services, setServices] = useState({});
    const [hasValidationError, setHasValidationError] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        // Check if the password cookie exists and matches the expected password
        const savedPassword = Cookies.get('adminPassword');
        if (savedPassword !== 'datavan123') {
            navigate('/admin'); // Redirect to the admin login page if not authenticated
        }
    }, [navigate]);

    const handleFileChange = (e) => {
        setSelectedFile(e.target.files[0]);
        setErrors([]); // Clear errors when a new file is selected
        setCsvData([]); // Clear existing CSV data when a new file is selected
    };

    const validateCsv = (data) => {
        const requiredHeaders = ['language', 'name', 'price'];
        const headerErrors = [];
        const contentErrors = [];
        const languageDataLengths = {};
        const pricesPerSet = {};

        // Check if headers match the required headers
        const headers = Object.keys(data[0]).map(header => header.trim());
        if (!requiredHeaders.every(header => headers.includes(header))) {
            headerErrors.push('CSV文件必須包含以下標題: language, name, price');
        }

        // Check if each row has all columns filled and track data lengths per language
        data.forEach((row, index) => {
            const isEmptyRow = Object.values(row).every(value => !value || value.trim() === '');
            if (isEmptyRow) {
                contentErrors.push(`第 ${index + 1} 行是空的`);
            } else {
                requiredHeaders.forEach((header) => {
                    if (!row[header] || row[header].trim() === '') {
                        contentErrors.push(`第 ${index + 1} 行的列 ${header} 有空值`);
                    }
                });

                // Track data lengths per language
                const language = row['language'];
                if (!languageDataLengths[language]) {
                    languageDataLengths[language] = 0;
                }
                languageDataLengths[language]++;

                // Track prices per set
                const price = parseFloat(row['price']);
                if (!pricesPerSet[language]) {
                    pricesPerSet[language] = [];
                }
                pricesPerSet[language].push(price);
            }
        });

        // Check if all languages have the same length of data
        const languageLengths = Object.values(languageDataLengths);
        const hasSameLength = languageLengths.every(length => length === languageLengths[0]);
        if (!hasSameLength) {
            contentErrors.push('所有語言必須具有相同數量的行。');
        }

        // Check if prices are identical across all four languages
        const firstLanguagePrices = Object.values(pricesPerSet)[0];
        const allPricesIdentical = Object.values(pricesPerSet).every(
            prices => prices.length === firstLanguagePrices.length && prices.every((price, index) => price === firstLanguagePrices[index])
        );
        if (!allPricesIdentical) {
            contentErrors.push('所有語言的價格必須相同。');
        }

        // Check if there is at least one row of data
        if (data.length < 1) {
            contentErrors.push('CSV文件必須包含至少一行數據');
        }

        return [...headerErrors, ...contentErrors];
    };

    useEffect(() => {
        const fetchSettings = async () => {
            try {
                const response = await axios.post(config.servicesUrl, {
                    hotel_id: "2222",
                    command: "get_qs"
                });
                if (response.status === 200) {
                    setServices(response.data.services);
                } else {
                    throw new Error(`Unexpected response status: ${response.status}`);
                }
            } catch (error) {
                console.error('無法獲取設置:', error);
                alert('無法獲取設置');
            }
        };

        fetchSettings();
    }, []);

    // Convert services object to an array of objects for displaying in the table
    const servicesArray = Object.keys(services).length > 0 ?
        Object.keys(services).flatMap(language =>
            services[language].map(service => ({ language, ...service }))
        ) : [];

    const handleFileUpload = () => {
        if (selectedFile) {
            Papa.parse(selectedFile, {
                header: true,
                skipEmptyLines: true,
                complete: (results) => {
                    const cleanedData = results.data.map(row => {
                        const cleanedRow = {};
                        Object.keys(row).forEach(key => {
                            cleanedRow[key.trim()] = row[key] ? row[key].trim() : '';
                        });
                        return cleanedRow;
                    }).filter(row => !Object.values(row).every(value => value === ''));

                    const validationErrors = validateCsv(cleanedData);
                    if (validationErrors.length > 0) {
                        setErrors(validationErrors);
                        setCsvData([]); // Clear existing CSV data when there are validation errors
                        setHasValidationError(true); // Set validation error state
                    } else {
                        setCsvData(cleanedData);
                        setErrors([]);
                        setHasValidationError(false); // Clear validation error state
                    }
                },
                error: (error) => {
                    console.error('解析CSV文件時出錯:', error);
                    setErrors(['解析CSV文件時出錯']);
                    setCsvData([]); // Clear existing CSV data when there is an error parsing the file
                    setHasValidationError(true); // Set validation error state
                }
            });
        }
    };

    const handleSubmit = async () => {
        try {
            const formattedData = csvData.reduce((acc, row) => {
                const { language, name, price } = row;
                if (!acc[language]) acc[language] = [];
                acc[language].push({ name, price: parseFloat(price) });
                return acc;
            }, {});

            const dataToSubmit = {
                hotel_id: "2222",
                command: "update_services",
                services: formattedData
            };

            const response = await axios.post(config.servicesUrl, dataToSubmit);
            if (response.status === 200) {
                console.log('服務更新成功!', response.data);
                alert('更新成功');
                window.location.reload(); // Reload the page
            } else {
                throw new Error(`Unexpected response status: ${response.status}`);
            }
        } catch (error) {
            console.error('無法更新服務:', error);
            console.log('無法更新服務');
        }
    };

    const downloadTemplate = () => {
        const csvContent = [
            ['language', 'name', 'price'],
            ['en-US', 'Glass', 0],
            ['en-US', 'Ice', 500],
            ['en-US', 'Bath towel', 300],
            ['ja-JP', 'グラス', 0],
            ['ja-JP', '氷', 500],
            ['ja-JP', 'バスタオル', 300],
            ['zh-TW', '玻璃杯', 0],
            ['zh-TW', '冰塊', 500],
            ['zh-TW', '浴巾', 300],
            ['ko-KR', '유리잔', 0],
            ['ko-KR', '얼음', 500],
            ['ko-KR', '목욕 타올', 300]
        ];
        const csvString = Papa.unparse(csvContent);
        const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'services.csv';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <div style={{ maxWidth: '1200px', margin: '0 auto', padding: '20px' }}> {/* Centered container with max-width */}
            <AdminLayout>
                {/* <h2>管理</h2> */}
                {/* Page content goes here */}
            </AdminLayout>
            <h1>
                客房サービス管理
                <br />
                <span style={{ fontSize: '1rem', color: 'grey' }}>Room Service Management</span>
            </h1>

            <input type="file" accept=".csv" onChange={handleFileChange} />
            <Button onClick={handleFileUpload}>
                アップロード
                <br />
                <span style={{ fontSize: '0.9rem', color: 'grey' }}>Upload</span>
            </Button>
            <br />

            <div className="d-flex justify-content-end">
                <Button onClick={downloadTemplate}>
                    テンプレートをダウンロード
                    <br />
                    <span style={{ fontSize: '0.9rem', color: 'grey' }}>Download Template</span>
                </Button>
            </div>

            <p>テンプレートをダウンロードし、すべてのフィールドが完全に入力されていることを確認してください。記入時に「,」カンマが含まれていないようにしてください。</p>

            {errors.length > 0 && (
                <div>
                    <h2>
                        エラー
                        <br />
                        <span style={{ fontSize: '1rem', color: 'grey' }}>Errors</span>
                    </h2>
                    <ul>
                        {errors.map((error, index) => (
                            <li key={index} style={{ color: 'red' }}>{error}</li>
                        ))}
                    </ul>
                </div>
            )}

            {csvData.length > 0 && !hasValidationError && (
                <div>
                    <div className="d-flex justify-content-end">
                        <Button
                            variant="success"
                            onClick={handleSubmit}
                            style={{ fontSize: '1.5rem', padding: '1rem 2rem' }}
                        >
                            提出
                            <br />
                            <span style={{ fontSize: '0.9rem', color: 'grey' }}>Submit</span>
                        </Button>
                    </div>
                    <h2>
                        プレビュー
                        <br />
                        <span style={{ fontSize: '1rem', color: 'grey' }}>Preview</span>
                    </h2>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Table striped bordered hover style={{ width: '100%' }}>
                            <thead>
                                <tr style={{ backgroundColor: '#343a40', color: '#ffffff' }}>
                                    {Object.keys(csvData[0]).map((header) => (
                                        <th key={header}>{header}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {csvData.map((row, index) => (
                                    <tr key={index}>
                                        {Object.values(row).map((value, idx) => (
                                            <td key={idx}>{value}</td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                </div>
            )}

            {csvData.length === 0 && servicesArray.length > 0 && !hasValidationError && (
                <div>
                    <h2>
                        現在のサービス
                        <br />
                        <span style={{ fontSize: '1rem', color: 'grey' }}>Current Services</span>
                    </h2>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Table striped bordered hover style={{ width: '100%' }}>
                            <thead>
                                <tr style={{ backgroundColor: '#343a40', color: '#ffffff' }}>
                                    {Object.keys(servicesArray[0]).map((header) => (
                                        <th key={header}>{header}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {servicesArray.map((row, index) => (
                                    <tr key={index}>
                                        {Object.values(row).map((value, idx) => (
                                            <td key={idx}>{value}</td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                </div>
            )}
        </div>
    );
};

export default QA;