import React, { useState, useEffect, forwardRef } from 'react';
import { Button, Form, Container, Row, Col, Card, ListGroup } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';
import moment from 'moment-timezone';
import config from './config';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import Login from './Login';
import * as XLSX from 'xlsx';
import AdminLayout from './AdminLayout'; // Import the layout component

const CustomDateInput = forwardRef(({ value, onClick }, ref) => (
    <button
        type="button"
        className="form-control"
        style={{ fontSize: '1.2rem', textAlign: 'left' }}
        onClick={onClick}
        ref={ref}
    >
        {value}
    </button>
));

const BillReport = () => {
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [reportData, setReportData] = useState([]);
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    // const navigate = useNavigate();

    // useEffect(() => {
    //     const auth = Cookies.get('auth');
    //     if (auth === 'datavan/123456') {
    //         setIsAuthenticated(true);
    //     } else {
    //         navigate('/login');
    //     }
    // }, [navigate]);


    const navigate = useNavigate();

    useEffect(() => {
        // Check if the password cookie exists and matches the expected password
        const savedPassword = Cookies.get('adminPassword');
        if (savedPassword === 'datavan123') {
            setIsAuthenticated(true);
        } else {
            navigate('/admin');
        }
    }, [navigate]);


    const handleGenerateReport = async () => {
        try {
            const formattedStartDate = moment(startDate).format('YYYY-MM-DD');
            const formattedEndDate = moment(endDate).format('YYYY-MM-DD');
            const response = await axios.post(config.servicesUrl, {
                command: "find_bills",
                start_date: formattedStartDate,
                end_date: formattedEndDate
            });
            setReportData(response.data);
        } catch (error) {
            console.error('レポートの取得に失敗しました:', error);
        }
    };

    const downloadExcel = () => {
        const workbook = XLSX.utils.book_new();

        const worksheetData = [
            // Consolidated header row
            [
                "_id", "booking_id", "command", "customer_info_city", "customer_info_email", "customer_info_name",
                "customer_info_special_requests", "customer_info_surname", "customer_info_telephone", "lastModified",
                "notes",
                "room_index", "room_number", "room_type", "room_adults", "room_children", "room_name", "room_cost", // Room bill details
                "room_bill_tax_amount", "room_bill_total_before_tax", "room_bill_total_including_tax",
                "index", "service_room_number", "service_name", "service_price", "service_quantity", "service_total_cost",
                "service_bill_date", "service_request_time", "service_delivered", // Service bill details
                "service_bill_tax_amount", "service_bill_total_before_tax", "service_bill_total_including_tax", "update_time",
                "grand_total" // Moved grand_total to the end
            ]
        ];

        // Populate each bill's data
        reportData.forEach((bill) => {
            const maxRows = Math.max(bill.room_bill_rooms.length, bill.service_bill_services.length);

            for (let i = 0; i < maxRows; i++) {
                const room = bill.room_bill_rooms[i] || {}; // If no room data, use empty object
                const service = bill.service_bill_services[i] || {}; // If no service data, use empty object

                worksheetData.push([
                    i === 0 ? bill._id : "", // Fill only once for primary data
                    i === 0 ? bill.booking_id : "",
                    i === 0 ? bill.command : "",
                    i === 0 ? bill.customer_info_city : "",
                    i === 0 ? bill.customer_info_email : "",
                    i === 0 ? bill.customer_info_name : "",
                    i === 0 ? bill.customer_info_special_requests : "",
                    i === 0 ? bill.customer_info_surname : "",
                    i === 0 ? bill.customer_info_telephone : "",
                    i === 0 ? formatDate(bill.lastModified) : "",
                    i === 0 ? bill.notes : "",

                    // Room details
                    room.room_index || "",
                    room.room_number || "",
                    room.room_type || "",
                    room.room_adults || "",
                    room.room_children || "",
                    room.room_name || "",
                    room.room_cost || "",

                    i === 0 ? bill.room_bill_tax_amount : "",
                    i === 0 ? bill.room_bill_total_before_tax : "",
                    i === 0 ? bill.room_bill_total_including_tax : "",

                    // Service details
                    service.index || "",
                    service.room_number || "",
                    service.service_name || "",
                    service.service_price || "",
                    service.service_quantity || "",
                    service.service_total_cost || "",
                    service.service_bill_date || "",
                    service.service_request_time || "",
                    service.service_delivered === "Yes" ? "はい" : service.service_delivered || "",

                    i === 0 ? bill.service_bill_tax_amount : "",
                    i === 0 ? bill.service_bill_total_before_tax : "",
                    i === 0 ? bill.service_bill_total_including_tax : "",
                    i === 0 ? formatDate(bill.update_time) : "",
                    i === 0 ? bill.grand_total : "" // Moved grand_total to the end
                ]);
            }

            // Add a blank row between bills for spacing
            worksheetData.push([]);
        });

        // Create the worksheet and add it to the workbook
        const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
        XLSX.utils.book_append_sheet(workbook, worksheet, "Billing Report");

        // Write the workbook to an Excel file
        XLSX.writeFile(workbook, "billing_report.xlsx");
    };





    const formatDate = (dateString) => {
        return dateString ? moment(dateString).tz('Asia/Tokyo').format('YYYY-MM-DD HH:mm:ss') : '';
    };

    const calculateCumulativeTotals = () => {
        return reportData.reduce(
            (totals, bill) => {
                totals.grandTotal += bill.grand_total || 0;
                totals.roomTotal += bill.room_bill_total_including_tax || 0;
                totals.serviceTotal += bill.service_bill_total_including_tax || 0;
                return totals;
            },
            { grandTotal: 0, roomTotal: 0, serviceTotal: 0 }
        );
    };

    const cumulativeTotals = calculateCumulativeTotals();

    if (!isAuthenticated) {
        return <Login onLogin={() => setIsAuthenticated(true)} />;
    }

    return (
        <Container>
            <Row className="justify-content-md-center">
                <AdminLayout>
                    {/* <h2>管理</h2> */}
                    {/* Page content goes here */}
                </AdminLayout>
                <Col md={8}>
                    <h2 style={{ fontSize: '2rem', marginBottom: '2rem', textAlign: 'center' }}>
                        請求レポート
                        <br />
                        <span style={{ fontSize: '1rem', color: 'grey' }}>Billing Report</span>
                    </h2>
                    <Form>
                        <Row className="align-items-end">
                            <Col md={4}>
                                <Form.Group controlId="formStartDate">
                                    <Form.Label>
                                        開始日
                                        <br />
                                        <span style={{ fontSize: '0.9rem', color: 'grey' }}>Start Date</span>
                                    </Form.Label>
                                    <DatePicker
                                        selected={startDate}
                                        onChange={(date) => setStartDate(date)}
                                        dateFormat="yyyy-MM-dd"
                                        customInput={<CustomDateInput />}
                                    />
                                </Form.Group>
                            </Col>

                            <Col md={4}>
                                <Form.Group controlId="formEndDate">
                                    <Form.Label>
                                        終了日
                                        <br />
                                        <span style={{ fontSize: '0.9rem', color: 'grey' }}>End Date</span>
                                    </Form.Label>
                                    <DatePicker
                                        selected={endDate}
                                        onChange={(date) => setEndDate(date)}
                                        dateFormat="yyyy-MM-dd"
                                        customInput={<CustomDateInput />}
                                    />
                                </Form.Group>
                            </Col>

                            <Col md={4}>
                                <Button
                                    variant="primary"
                                    onClick={handleGenerateReport}
                                    style={{ fontSize: '1.2rem', padding: '10px 20px', marginTop: '8px' }}
                                >
                                    レポートを生成
                                    <br />
                                    <span style={{ fontSize: '0.9rem', color: 'grey' }}>Generate Report</span>
                                </Button>
                            </Col>
                        </Row>
                    </Form>

                    {/* Cumulative Totals Section at the top */}
                    {reportData.length > 0 && (
                        <>
                            <Card style={{ marginTop: '2rem', marginBottom: '2rem', border: '3px solid green' }}>
                                <Card.Body>
                                    <h3 style={{ color: 'green', fontWeight: 'bold' }}>累積合計</h3>
                                    <div style={{ fontSize: '1.5rem', color: 'black', marginTop: '1rem' }}>
                                        <strong>総合計 (全ての請求):</strong> ¥ {cumulativeTotals.grandTotal.toLocaleString()}
                                    </div>
                                    <div style={{ fontSize: '1.5rem', color: 'black', marginTop: '0.5rem' }}>
                                        <strong>部屋合計 (税込 - 全ての請求):</strong> ¥ {cumulativeTotals.roomTotal.toLocaleString()}
                                    </div>
                                    <div style={{ fontSize: '1.5rem', color: 'black', marginTop: '0.5rem' }}>
                                        <strong>サービス合計 (税込 - 全ての請求):</strong> ¥ {cumulativeTotals.serviceTotal.toLocaleString()}
                                    </div>
                                </Card.Body>
                            </Card>

                            <Button
                                variant="secondary"
                                onClick={downloadExcel}
                                style={{ marginBottom: '2rem', fontSize: '1.2rem' }}
                            >
                                Excelとしてダウンロード
                            </Button>

                            {/* Individual report data rendering */}
                            <div style={{ marginTop: '2rem' }}>
                                {reportData.map((bill, index) => (
                                    <Card key={bill._id} style={{ marginBottom: '1.5rem', border: '2px solid #007bff' }}>
                                        <Card.Header style={{ fontSize: '1.5rem', color: '#007bff' }}>
                                            予約ID: {bill.booking_id} - 顧客: {bill.customer_info_name}
                                        </Card.Header>
                                        <Card.Body>
                                            <div>
                                                <strong>総合計:</strong> ¥ {bill.grand_total.toLocaleString()}
                                            </div>
                                            <div>
                                                <strong>部屋合計 (税込):</strong> ¥ {bill.room_bill_total_including_tax.toLocaleString()}
                                            </div>
                                            <div>
                                                <strong>サービス合計 (税込):</strong> ¥ {bill.service_bill_total_including_tax.toLocaleString()}
                                            </div>
                                            <div>
                                                <strong>最終更新日:</strong> {formatDate(bill.lastModified)}
                                            </div>

                                            {/* Room Bill Details */}
                                            <h5 style={{ marginTop: '1rem', color: '#007bff' }}>部屋の詳細</h5>
                                            <ListGroup variant="flush">
                                                {bill.room_bill_rooms.map((room, idx) => (
                                                    <ListGroup.Item key={idx}>
                                                        <div><strong>部屋 {room.room_number}:</strong> {room.room_type}</div>
                                                        <div>大人: {room.room_adults}, 子供: {room.room_children}</div>
                                                        <div>宿泊者名: {room.room_name}</div>
                                                        <div>コスト: {room.room_cost}</div>
                                                    </ListGroup.Item>
                                                ))}
                                            </ListGroup>

                                            {/* Service Bill Details */}
                                            <h5 style={{ marginTop: '1rem', color: '#007bff' }}>サービスの詳細</h5>
                                            <ListGroup variant="flush">
                                                {bill.service_bill_services.map((service, idx) => (
                                                    <ListGroup.Item key={idx}>
                                                        <div><strong>サービス名:</strong> {service.service_name}</div>
                                                        <div>部屋番号: {service.room_number}</div>
                                                        <div>価格: {service.service_price}</div>
                                                        <div>数量: {service.service_quantity}</div>
                                                        <div>合計コスト: {service.service_total_cost}</div>
                                                        <div>リクエスト時間: {formatDate(service.service_request_time)}</div>
                                                        <div>提供済み: {service.service_delivered === "Yes" ? "はい" : "いいえ"}</div>
                                                    </ListGroup.Item>
                                                ))}
                                            </ListGroup>
                                        </Card.Body>
                                    </Card>
                                ))}
                            </div>
                        </>
                    )}
                </Col>
            </Row>
        </Container>
    );
};

export default BillReport;

