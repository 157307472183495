import React, { useState, useEffect } from 'react';
import Papa from 'papaparse';
import { Button, Table } from 'react-bootstrap';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import config from './config';
import AdminLayout from './AdminLayout'; // Import the layout component

const QA = () => {
    const [csvData, setCsvData] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const [errors, setErrors] = useState([]);
    const [questions, setQuestions] = useState({});
    const [hasValidationError, setHasValidationError] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        // Check if the password cookie exists and matches the expected password
        const savedPassword = Cookies.get('adminPassword');
        if (savedPassword !== 'datavan123') {
            navigate('/admin'); // Redirect to the admin login page if not authenticated
        }
    }, [navigate]);

    const handleFileChange = (e) => {
        setSelectedFile(e.target.files[0]);
        setErrors([]); // Clear errors when a new file is selected
        setCsvData([]); // Clear existing CSV data when a new file is selected
    };

    const validateCsv = (data) => {
        const requiredHeaders = ['ja-JP', 'zh-TW', 'en-US', 'ko-KR'];
        const headerErrors = [];
        const contentErrors = [];

        // Check if headers match the required headers
        const headers = Object.keys(data[0]).map(header => header.trim());
        if (!requiredHeaders.every(header => headers.includes(header))) {
            headerErrors.push('CSV文件必須包含標題：ja-JP, zh-TW, en-US, ko-KR');
        }

        // Check if each row has all columns filled
        data.forEach((row, index) => {
            const isEmptyRow = Object.values(row).every(value => !value || value.trim() === '');
            if (isEmptyRow) {
                contentErrors.push(`第${index + 1}行是空的`);
            } else {
                requiredHeaders.forEach((header) => {
                    if (!row[header] || row[header].trim() === '') {
                        contentErrors.push(`第${index + 1}行的${header}列是空的`);
                    }
                });
            }
        });

        // Check if there is at least one row of data
        if (data.length < 1) {
            contentErrors.push('CSV文件必須包含至少一行數據');
        }

        return [...headerErrors, ...contentErrors];
    };

    useEffect(() => {
        const fetchSettings = async () => {
            try {
                const response = await axios.post(config.servicesUrl, {
                    hotel_id: "2222",
                    command: "get_qs"
                });
                if (response.status === 200) {
                    setQuestions(response.data.questions);
                } else {
                    throw new Error(`Unexpected response status: ${response.status}`);
                }
            } catch (error) {
                console.error('獲取設置失敗:', error);
                alert('獲取設置失敗');
            }
        };

        fetchSettings();
    }, []);

    const handleFileUpload = () => {
        if (selectedFile) {
            Papa.parse(selectedFile, {
                header: true,
                skipEmptyLines: true,
                complete: (results) => {
                    const cleanedData = results.data.map(row => {
                        const cleanedRow = {};
                        Object.keys(row).forEach(key => {
                            cleanedRow[key.trim()] = row[key] ? row[key].trim() : '';
                        });
                        return cleanedRow;
                    }).filter(row => !Object.values(row).every(value => value === ''));

                    const validationErrors = validateCsv(cleanedData);
                    if (validationErrors.length > 0) {
                        setErrors(validationErrors);
                        setCsvData([]); // Clear existing CSV data when there are validation errors
                        setHasValidationError(true); // Set validation error state
                    } else {
                        setCsvData(cleanedData);
                        setErrors([]);
                        setHasValidationError(false); // Clear validation error state
                    }
                },
                error: (error) => {
                    console.error('解析CSV文件時出錯:', error);
                    setErrors(['解析CSV文件時出錯']);
                    setCsvData([]); // Clear existing CSV data when there is an error parsing the file
                    setHasValidationError(true); // Set validation error state
                }
            });
        }
    };

    const handleSubmit = async () => {
        try {
            const formattedData = csvData.reduce((acc, row) => {
                Object.keys(row).forEach((language) => {
                    if (!acc[language]) acc[language] = [];
                    acc[language].push(row[language]);
                });
                return acc;
            }, {});

            const dataToSubmit = {
                hotel_id: "2222",
                command: "update_qs",
                questions: formattedData
            };

            const response = await axios.post(config.servicesUrl, dataToSubmit);
            if (response.status === 200) {
                console.log('問題更新成功!', response.data);
                alert('更新成功');
                window.location.reload(); // Reload the page
            } else {
                throw new Error(`Unexpected response status: ${response.status}`);
            }
        } catch (error) {
            console.error('問題更新失敗:', error);
            console.log('問題更新失敗');
        }
    };

    const downloadTemplate = () => {
        const csvContent = [
            ['ja-JP', 'zh-TW', 'en-US', 'ko-KR'],
            ['新穂高之雪の月ホテルの住所はどこですか？', '新穗高雪之月飯店的地址是什麼？', 'What is the address of Yukino Tsuki Hotel in Shin-Hotaka?', '신호타카 유키노 츠키 호텔의 주소는 어디인가요?'],
            ['新穂高之雪の月のチェックインとチェックアウト時間は？', '新穗高雪之月的入住和退房時間是什麼時候？', 'What are the check-in and check-out times at Yukino Tsuki in Shin-Hotaka?', '신호타카 유키노 츠키의 체크인 및 체크아웃 시간은 언제인가요?']
        ];
        const csvString = Papa.unparse(csvContent);
        const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'question_example.csv';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    // Convert questions object to an array of objects for displaying in the table
    const questionsArray = Object.keys(questions).length > 0 ?
        Object.keys(questions['ja-JP']).map((_, index) => ({
            'ja-JP': questions['ja-JP'][index],
            'zh-TW': questions['zh-TW'][index],
            'en-US': questions['en-US'][index],
            'ko-KR': questions['ko-KR'][index],
        })) : [];

    return (
        <div style={{ maxWidth: '1200px', margin: '0 auto', padding: '20px' }}> {/* Centered container with max-width */}
            <AdminLayout>
                {/* <h2>管理</h2> */}
                {/* Page content goes here */}
            </AdminLayout>
            <h1>
                問題ボタン管理
                <br />
                <span style={{ fontSize: '1rem', color: 'grey' }}>Question Button Management</span>
            </h1>

            <input type="file" accept=".csv" onChange={handleFileChange} />
            <Button onClick={handleFileUpload}>
                アップロード
                <br />
                <span style={{ fontSize: '0.9rem', color: 'grey' }}>Upload</span>
            </Button>
            <br />

            <div className="d-flex justify-content-end">
                <Button onClick={downloadTemplate}>
                    テンプレートをダウンロード
                    <br />
                    <span style={{ fontSize: '0.9rem', color: 'grey' }}>Download Template</span>
                </Button>
            </div>

            <p>テンプレートをダウンロードし、四つの言語の内容がすべて完成していることを確認してください。記入時に「,」カンマが含まれていないようにしてください。</p>

            {errors.length > 0 && (
                <div>
                    <h2>
                        エラー
                        <br />
                        <span style={{ fontSize: '1rem', color: 'grey' }}>Errors</span>
                    </h2>
                    <ul>
                        {errors.map((error, index) => (
                            <li key={index} style={{ color: 'red' }}>{error}</li>
                        ))}
                    </ul>
                </div>
            )}

            {csvData.length > 0 && !hasValidationError && (
                <div>
                    <div className="d-flex justify-content-end">
                        <Button
                            variant="success"
                            onClick={handleSubmit}
                            style={{ fontSize: '1.5rem', padding: '1rem 2rem' }}
                        >
                            提出
                            <br />
                            <span style={{ fontSize: '0.9rem', color: 'grey' }}>Submit</span>
                        </Button>
                    </div>
                    <h2>
                        プレビュー
                        <br />
                        <span style={{ fontSize: '1rem', color: 'grey' }}>Preview</span>
                    </h2>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Table striped bordered hover style={{ width: '100%' }}>
                            <thead>
                                <tr style={{ backgroundColor: '#343a40', color: '#ffffff' }}>
                                    {Object.keys(csvData[0]).map((header) => (
                                        <th key={header}>{header}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {csvData.map((row, index) => (
                                    <tr key={index}>
                                        {Object.values(row).map((value, idx) => (
                                            <td key={idx}>{value}</td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                </div>
            )}

            {csvData.length === 0 && questionsArray.length > 0 && !hasValidationError && (
                <div>
                    <h2>
                        現在の質問
                        <br />
                        <span style={{ fontSize: '1rem', color: 'grey' }}>Current Questions</span>
                    </h2>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Table striped bordered hover style={{ width: '100%' }}>
                            <thead>
                                <tr style={{ backgroundColor: '#343a40', color: '#ffffff' }}>
                                    {Object.keys(questionsArray[0]).map((language) => (
                                        <th key={language}>{language}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {questionsArray.map((row, index) => (
                                    <tr key={index}>
                                        {Object.values(row).map((question, idx) => (
                                            <td key={idx}>{question}</td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                </div>
            )}
        </div>
    );
};

export default QA;
