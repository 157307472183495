import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FaHome, FaSignOutAlt } from 'react-icons/fa';
import { Container } from 'react-bootstrap';
import Cookies from 'js-cookie';

const AdminLayout = ({ children, setIsAuthenticated }) => {
    const navigate = useNavigate();

    const handleHomeClick = () => {
        navigate('/admin');
    };

    const handleLogout = () => {
        Cookies.remove('adminPassword');
        // setIsAuthenticated(false);
        window.location.href = `${window.location.origin}/admin`; // Redirects to the full path of the admin page
    };

    return (
        <Container>
            {/* Home Icon and Logout Section with Light Grey Background */}
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: '#f0f0f0',
                    padding: '10px',
                    borderRadius: '8px',
                    margin: '10px',
                    position: 'relative'
                }}
            >
                {/* Home Icon Section - Centered */}
                <div style={{ flex: 1, display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                    <FaHome
                        onClick={handleHomeClick}
                        style={{ cursor: 'pointer', fontSize: '4rem', color: 'darkorange' }}
                        title="Go to Admin Home"
                    />
                    <p style={{ fontSize: '0.9rem', color: 'grey', marginTop: '5px' }}>管理ページ</p>
                </div>

                {/* Logout Icon Section - Positioned to the Right */}
                <div
                    onClick={handleLogout}
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        cursor: 'pointer',
                        position: 'absolute',
                        right: '10px',
                        top: '50%',
                        transform: 'translateY(-50%)'
                    }}
                >
                    <FaSignOutAlt
                        style={{ fontSize: '3.5rem', color: 'darkorange' }}
                        title="Logout"
                    />
                    <p style={{ fontSize: '0.9rem', color: 'grey', marginTop: '5px' }}>
                        ログアウト / Logout
                    </p>
                </div>
            </div>
            {/* Page Content */}
            <div style={{ marginTop: '20px' }}>
                {children}
            </div>
        </Container>
    );
};

export default AdminLayout;
