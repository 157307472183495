import React, { useEffect, useState, useCallback } from 'react';
import { Container, Row, Col, Card, Table, Button } from 'react-bootstrap';
import axios from 'axios';
import moment from 'moment';
import config from './config';

const QueryDataPage = () => {
  const [queryData, setQueryData] = useState(null);
  const [groupedServices, setGroupedServices] = useState({});
  const [additionalNotes, setAdditionalNotes] = useState(''); // State to store additional notes
  const [printTimestamp, setPrintTimestamp] = useState('');


  const parseQueryString = (queryString) => {
    if (queryString.startsWith('?')) {
      queryString = queryString.substring(1);
    }

    if (!queryString) {
      return null;
    }

    const params = queryString.split('&');
    const result = {};

    params.forEach(param => {
      const [key, value] = param.split('=');
      if (key && value !== undefined) {
        const decodedValue = decodeURIComponent(value.replace(/\+/g, ' '));
        const keys = key.split('.');
        keys.reduce((acc, currentKey, index) => {
          if (index === keys.length - 1) {
            acc[currentKey] = decodedValue;
          } else {
            if (!acc[currentKey]) {
              acc[currentKey] = isNaN(keys[index + 1]) ? {} : [];
            }
          }
          return acc[currentKey];
        }, result);
      }
    });

    if (result.order_detial?.rooms) {
      result.order_detial.rooms = Object.values(result.order_detial.rooms);
    }

    const checkinDate = result.order_detial?.date?.checkin_date;
    const checkoutDate = result.order_detial?.date?.checkout_date;
    if (checkinDate && checkoutDate) {
      const checkin = new Date(checkinDate);
      const checkout = new Date(checkoutDate);
      const diffTime = Math.abs(checkout - checkin);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      result.order_detial.booking_info.nights = diffDays.toString();
    }

    return result;
  };

  useEffect(() => {
    const queryString = window.location.search;
    const data = parseQueryString(queryString);
    setQueryData(data);
  }, []);

  const fetchServiceReports = useCallback(async (startDate, endDate, selectedRooms) => {
    try {
      const formattedStartDate = moment(startDate).format('YYYY-MM-DD');
      const adjustedEndDate = moment(endDate).subtract(1, 'days').format('YYYY-MM-DD'); // Adjust end date by subtracting one day

      const response = await axios.post(config.servicesUrl, {
        command: "service_reports",
        room: selectedRooms.join(','),
        start_date: formattedStartDate,
        end_date: adjustedEndDate // Use the adjusted end date
      });

      const groupedData = groupByRoom(response.data);
      setGroupedServices(groupedData);
    } catch (error) {
      console.error('Failed to fetch report:', error);
    }
  }, []);

  const groupByRoom = (data) => {
    return data.reduce((acc, curr) => {
      const room = curr.room;
      if (!acc[room]) {
        acc[room] = [];
      }
      acc[room].push(curr);
      return acc;
    }, {});
  };


  useEffect(() => {
    if (queryData) {
      const { checkin_date, checkout_date } = queryData.order_detial.date;
      const selectedRooms = queryData.order_detial.rooms.map(room => room.room_num);
      fetchServiceReports(checkin_date, checkout_date, selectedRooms);
    }
  }, [queryData, fetchServiceReports]);

  const totalAmountBeforeTax = Object.keys(groupedServices).reduce(
    (acc, room) =>
      acc +
      groupedServices[room]
        .filter(service => service.checked)
        .reduce((roomAcc, service) => roomAcc + service.price * service.qty, 0),
    0
  );

  const taxAmount = Math.floor(totalAmountBeforeTax * 0.1);
  const totalIncludingTax = Math.floor(totalAmountBeforeTax * 1.1);
  const roomTotalIncludingTax = Math.floor(queryData?.order_detial?.row_info?.total || 0);
  const grandTotal = roomTotalIncludingTax + totalIncludingTax;



  const generateServiceBillServicesArray = () => {
    let serviceIndex = 0; // Initialize a unique index counter

    return Object.keys(groupedServices).flatMap(room =>
      groupedServices[room].map(service => {
        serviceIndex += 1; // Increment for each service item
        return {
          index: serviceIndex,
          room_number: room,
          service_name: `${service.name}`,
          service_price: `¥ ${service.price}`,
          service_quantity: service.qty,
          service_total_cost: `¥ ${service.total}`,
          service_bill_date: service.bill_date,
          service_request_time: moment(service.request_timestamp).format('YYYY-MM-DD HH:mm'),
          service_delivered: service.checked ? 'Yes' : 'No',
        };
      })
    );
  };



  const handlePrint = async () => {
    try {

      const currentTimestamp = new Date().toISOString();
      setPrintTimestamp(currentTimestamp);  // Set print timestamp here


      const postData = {
        command: "checkout_bill",
        booking_id: queryData.order_detial.booking_info.id,
        // Update Time in ISO format
        update_time: currentTimestamp,
        grand_total: grandTotal,

        // Customer Info
        customer_info_name: queryData.order_detial.customer_details.name,
        customer_info_surname: queryData.order_detial.customer_details.surname,
        customer_info_email: queryData.order_detial.customer_details.email,
        customer_info_city: queryData.order_detial.customer_details.city,
        customer_info_telephone: queryData.order_detial.customer_details.telephone,
        customer_info_special_requests: queryData.order_detial.customer_details.special_requests || 'None',

        // Room Bill Info
        room_bill_total_before_tax: roomTotalIncludingTax - queryData.order_detial.row_info.tot_taxes,
        room_bill_tax_amount: Math.floor(Number(queryData.order_detial.row_info.tot_taxes)),

        room_bill_total_including_tax: roomTotalIncludingTax,
        // room_bill_rooms: queryData.order_detial.rooms.map((room, index) => ({
        //   room_index: index + 1, // Add index starting from 1
        //   room_number: room.room_num,
        //   room_type: room.room_type,
        //   room_adults: room.adults,
        //   room_children: room.children,
        //   room_name: `${room.t_last_name} ${room.t_first_name}`,
        //   room_cost: room.cust_cost,
        // })),

        room_bill_rooms: queryData.order_detial.rooms
          .filter(room => room.room_num) // Filter to only include entries with room_num
          .map((room, index) => ({
            room_index: index + 1, // Add index starting from 1
            room_number: room.room_num,
            room_type: room.room_type,
            room_adults: room.adults,
            room_children: room.children,
            room_name: `${room.t_last_name} ${room.t_first_name}`,
            // room_cost: room.cust_cost,
          })),


        // Service Bill Info
        service_bill_total_before_tax: totalAmountBeforeTax,
        service_bill_tax_amount: taxAmount,
        service_bill_total_including_tax: totalIncludingTax,

        // other details...
        service_bill_services: generateServiceBillServicesArray(),

        // Note Message from Additional Notes input
        notes: additionalNotes || 'No additional notes',
      };

      await axios.post(config.servicesUrl, postData);
      window.print();
    } catch (error) {
      console.error("Failed to post checkout data:", error);
    }
  };




  return (
    <Container fluid className="bg-white text-dark p-3">

      {queryData ? (
        <>


          <Row className="mb-3">
            <Col>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                <img
                  src="https://www.yukinotsuki-hotel.jp/wp-content/uploads/2024/10/logo1-04.png"
                  alt="Logo"
                  style={{ width: '150px' }}
                />
                <span style={{ fontSize: '1.5rem', fontWeight: 'bold', textAlign: 'center', flexGrow: 1 }}>
                  Invoice
                </span>
                <img
                  src="https://www.yukinotsuki-hotel.jp/wp-content/uploads/2024/10/logo1-06-1.png"
                  alt="Hotel Address"
                  style={{ width: '190px' }}
                />
              </div>
            </Col>
          </Row>



          {/* Booking Details Section */}
          <Row className="mb-3">
            <Col>
              <Card className="bg-white text-dark p-3">
                <Card.Body>
                  <Table borderless className="booking-details-table">
                    <tbody>
                      <tr>
                        <td><div><span style={{ color: 'lightgrey' }}>Booking ID:</span><br /><strong>{queryData.order_detial.booking_info.id}</strong></div></td>
                        <td><div><span style={{ color: 'lightgrey' }}>Name:</span><br /><strong>{queryData.order_detial.customer_details.name}</strong></div></td>
                        <td><div><span style={{ color: 'lightgrey' }}>Email:</span><br /><strong>{queryData.order_detial.customer_details.email}</strong></div></td>
                        <td><div><span style={{ color: 'lightgrey' }}>City:</span><br /><strong>{queryData.order_detial.customer_details.city}</strong></div></td>
                        <td><div><span style={{ color: 'lightgrey' }}>Surname:</span><br /><strong>{queryData.order_detial.customer_details.surname}</strong></div></td>
                      </tr>
                      <tr>
                        <td><div><span style={{ color: 'lightgrey' }}>Phone:</span><br /><strong>{queryData.order_detial.customer_details.telephone}</strong></div></td>
                        <td><div><span style={{ color: 'lightgrey' }}>Check-in:</span><br /><strong>{queryData.order_detial.date.checkin_date}</strong></div></td>
                        <td><div><span style={{ color: 'lightgrey' }}>Check-out:</span><br /><strong>{queryData.order_detial.date.checkout_date}</strong></div></td>
                        <td><div><span style={{ color: 'lightgrey' }}>Nights:</span><br /><strong>{queryData.order_detial.booking_info.nights}</strong></div></td>
                        <td><div><span style={{ color: 'lightgrey' }}>Special Requests:</span><br /><strong>{queryData.order_detial.customer_details.special_requests || 'None'}</strong></div></td>
                      </tr>
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>
          </Row>


          {/* Room Summary Section */}
          {/* <Row className="mb-3">
            <Col>
              <Card className="bg-white text-dark mb-3">
                <Card.Body>
                  <Table bordered>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Room Number</th>
                        <th>Room Type</th>
                        <th>Adults</th>
                        <th>Children</th>
                        <th>Name</th>
                        <th>Cost (JPY) TAX incl.</th>
                      </tr>
                    </thead>
                    <tbody>
                      {queryData.order_detial.rooms.map((room, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{room.room_num}</td>
                          <td>{room.room_type}</td>
                          <td>{room.adults}</td>
                          <td>{room.children}</td>
                          <td>{room.t_last_name}{room.t_first_name}</td>
                          <td>¥ {room.cust_cost}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>
          </Row> */}

          {/* Room Numbers Summary */}
          <Row className="mb-3">
            <Col>
              {/* <Card className="bg-white text-dark p-1">
                <Card.Body>
                  <p style={{ fontWeight: 'bold', fontSize: '1rem' }}>
                    Room numbers: {
                      queryData.order_detial.rooms
                        .filter(room => room.room_num)  // Filter to only include entries with room_num
                        .map(room => room.room_num)
                        .join(', ')
                    }, total {queryData.order_detial.rooms.filter(room => room.room_num).length} rooms.
                  </p>
                </Card.Body>
              </Card> */}
            </Col>
          </Row>


          {/* Room Total Summary Section */}
          <Row>
            <Col>

              <Card className="bg-white text-dark p-1">
                <Card.Body>
                  <p style={{ fontWeight: 'bold', fontSize: '1.6rem' }}>
                    {queryData.order_detial.rooms.filter(room => room.room_num).length} rooms : {
                      queryData.order_detial.rooms
                        .filter(room => room.room_num)  // Filter to only include entries with room_num
                        .map(room => room.room_num)
                        .join(', ')
                    }
                  </p>
                </Card.Body>
                {/* </Card>
              <Card className="bg-white text-dark p-1"> */}
                <Card.Body>
                  <Row>
                    <Col style={{ textAlign: 'left' }}>
                      <p>
                        Room total bef. Tax:<br />
                        <span>¥ {Math.floor(roomTotalIncludingTax - queryData.order_detial.row_info.tot_taxes)}</span>
                      </p>
                    </Col>
                    <Col style={{ textAlign: 'center' }}>
                      <p>
                        Room Tax:<br />
                        <span>¥ {Math.floor(queryData.order_detial.row_info.tot_taxes)}</span>
                      </p>
                    </Col>
                    <Col style={{ textAlign: 'right' }}>
                      <p>
                        Room Total inc. Tax:<br />
                        <strong style={{ fontSize: '1.2rem' }}>¥ {roomTotalIncludingTax.toLocaleString()}</strong>
                      </p>


                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>



          <Row className="mb-3">
            <Col>
              <Card className="bg-white text-dark mb-3">
                <Card.Body>
                  <h4>Room Service</h4>
                  <p style={{ fontSize: '0.8rem', color: 'black' }}>
                    Bill Date closing time is 12:00 noon.
                  </p>
                  <Table bordered>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Room Number</th>
                        <th>Service Name</th>
                        <th>Price (JPY)</th>
                        <th>Quantity</th>
                        <th>Total Cost</th>
                        <th>Bill Date</th>
                        <th>Request Time</th>
                        <th>Delivered</th>
                      </tr>
                    </thead>
                    <tbody>
                      {(() => {
                        let serviceIndex = 0; // Initialize a unique index counter

                        return Object.keys(groupedServices).flatMap(room =>
                          groupedServices[room].map(service => {
                            serviceIndex += 1; // Increment for each service item
                            const isChecked = service.checked;
                            const textStyle = {
                              textDecoration: isChecked ? 'none' : 'line-through',
                              color: isChecked ? 'black' : 'grey'
                            };

                            return (
                              <tr key={`${room}-${serviceIndex}`}>
                                <td style={textStyle}>{serviceIndex}</td>
                                <td style={textStyle}>{room}</td>
                                <td style={textStyle}>{service.name}</td>
                                <td style={textStyle}>¥ {service.price}</td>
                                <td style={textStyle}>{service.qty}</td>
                                <td style={textStyle}>¥ {service.total}</td>
                                <td style={textStyle}>{service.bill_date}</td>
                                <td style={textStyle}>{moment(service.request_timestamp).format('YYYY-MM-DD HH:mm')}</td>
                                <td style={textStyle}>{isChecked ? 'Yes' : 'No'}</td>
                              </tr>
                            );
                          })
                        );
                      })()}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>
          </Row>



          {/* Service Total Summary Section */}
          <Row className="mb-3">
            <Col>
              <Card className="bg-white text-dark p-1">
                <Card.Body>
                  <Row className="justify-content-between align-items-center">
                    <Col style={{ textAlign: 'left' }}>
                      <p>
                        Total Amount Before Tax:<br />
                        <span>¥{totalAmountBeforeTax}</span>
                      </p>
                    </Col>
                    <Col style={{ textAlign: 'center' }}>
                      <p>
                        Tax (10%):<br />
                        <span>¥{taxAmount}</span>
                      </p>
                    </Col>
                    <Col style={{ textAlign: 'right' }}>
                      <p>
                        Total Including Tax:<br />
                        <strong style={{ fontSize: '1.2rem' }}>¥ {totalIncludingTax.toLocaleString()}</strong>
                      </p>

                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>


          {/* Grand Total Section */}
          <Row className="mb-3">
            <Col>
              <Card className="bg-white text-dark p-1">
                <Card.Body>
                  <Row className="justify-content-end align-items-center">
                    <Col style={{ textAlign: 'right' }}>
                      <p>
                        <span style={{
                          backgroundColor: '#ffcccc',  // Set background color to lighter red
                          color: 'black',              // Set text color to black
                          padding: '20px',
                          borderRadius: '5px',
                          border: '2px solid black'    // Set border color to black
                        }}>
                          Grand Total: <strong style={{ fontSize: '1.5rem' }}> ¥ {grandTotal.toLocaleString()}</strong>
                        </span>
                      </p>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>




          {/* Additional Notes Section */}
          <Row>
            <Col>
              <Card.Body>
                <h5>Additional Notes</h5>
                <textarea
                  placeholder="Enter admin notes here..."
                  value={additionalNotes}
                  onChange={(e) => setAdditionalNotes(e.target.value)} // Update state on input change
                  rows={4}
                  style={{
                    width: '100%',
                    fontWeight: 'bold',
                    padding: '10px',
                    border: '4px dotted blue',
                  }}
                />
              </Card.Body>
            </Col>
          </Row>

          {printTimestamp && (
            <Row className="mb-3">
              <Col>
                <p style={{ textAlign: 'right', fontSize: '0.8rem', color: 'grey' }}>
                  Print Time: {moment(printTimestamp).format('YYYY-MM-DD HH:mm:ss')}
                </p>
              </Col>
            </Row>
          )}

          {/* Print Button */}
          <Row className="mb-3 justify-content-center">
            <Button
              onClick={handlePrint}
              variant="primary"
              style={{ marginBottom: '40px', width: '450px', fontSize: '1.5rem' }} // Adjust font size here
              id="printButton"
            >
              Confirm & Print
            </Button>

            <style>
              {`
      @media print {
        @page {
          margin: 15px; /* Updated margin for page */
        }
        body {
          margin: 1cm; /* Adjusted body margin for print */
        }
        
        /* Hide elements that are not needed for print */
        #printButton, 
        .non-printable {
          display: none !important;
        }
        
        /* Adjust the font size for better readability on print */
        h5, p, strong {
          font-size: 1.1rem;
        }
        
        /* Customize textarea for printing */
        textarea {
          border: none;
          background: none;
          color: black; /* Ensure text color is visible */
        }
        
        /* Hide placeholder text when printing */
        textarea::placeholder {
          color: transparent;
        }
        
        /* Keep the entire print-container on one page */
        .print-container {
          page-break-inside: avoid;
        }
      }
    `}
            </style>
          </Row>


        </>
      ) : (
        <p>No data</p>
      )}
    </Container>
  );
};

export default QueryDataPage;
