import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import RoomService from './RoomService';
import Test from './Test';
import 'bootstrap/dist/css/bootstrap.min.css';
import Questions from './Questions';
import Services from './Services';
import Wifi from './Wifi';
import AdminControlPage from './AdminControlPage';
import Reception from './Reception';
import Delivery from './Delivery';
import ServiceReport from './ServiceReport';
import Login from './Login';
import Invoice1 from './Invoice1';
import Younger from './Younger';
import Search from './Search'; // Import the Search component
import BillReport from './BillReport'; // Import the BillReport component

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<RoomService />} />
        <Route path="/roomservice" element={<RoomService />} />
        <Route path="/test" element={<Test />} />
        <Route path="/questions" element={<Questions />} />
        <Route path="/services" element={<Services />} />
        <Route path="/wifi" element={<Wifi />} />
        <Route path="/admin" element={<AdminControlPage />} />
        <Route path="/reception" element={<Reception />} />
        <Route path="/delivery" element={<Delivery />} />
        <Route path="/login" element={<Login onLogin={() => { }} />} />
        <Route path="/servicereport" element={<ServiceReport />} />
        <Route path="/invoice1" element={<Invoice1 />} />
        <Route path="/younger" element={<Younger />} />
        <Route path="/search" element={<Search />} /> {/* Add the new route here */}
        <Route path="/billreport" element={<BillReport />} /> {/* Add the new route here */}
      </Routes>
    </Router>
  );
}

export default App;
