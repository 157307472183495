// import React, { useState, useEffect, useCallback, useRef } from 'react';
import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMicrophone, faCommentDots, faWifi, faKey, faPen, faHome, faSync, faVolumeMute } from '@fortawesome/free-solid-svg-icons';
import sentSound from './sent2.mp3';
import Spinner from 'react-bootstrap/Spinner';
import { Button, Modal, Carousel, Card, ListGroup } from 'react-bootstrap';
import axios from 'axios';
// import moment from 'moment'; // Import moment for date formatting
import moment from 'moment-timezone';
import Cookies from 'js-cookie';
import './App.css';  // Import your CSS file here
import { v4 as uuidv4 } from 'uuid';
import config from './config';
import Weather from './Weather'; // Import the Weather component

function App() {
  const [inputText, setInputText] = useState('');
  const [responseText, setResponseText] = useState('');
  const [queue, setQueue] = useState([]);
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [talking, setTalking] = useState(false);
  const [sessionStarted, setSessionStarted] = useState(false);
  // const [initialQueueSize, setInitialQueueSize] = useState(0);
  const [isListening, setIsListening] = useState(false);
  const [language, setLanguage] = useState('ja-JP'); // Default language is Japanese
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [fetchedServices, setFetchedServices] = useState([]);
  const [thinking, setThinking] = useState(false);
  const [wifiId, setWifiId] = useState('');
  const [wifiPassword, setWifiPassword] = useState('');
  const recognitionRef = useRef(null);
  const inputTextRef = useRef(inputText);
  const [inputRoomNumber, setInputRoomNumber] = useState('');
  const [password, setPassword] = useState('');
  const [isPasswordVerified, setIsPasswordVerified] = useState(false);
  const [showPasswordInput, setShowPasswordInput] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [contentSource, setContentSource] = useState('');
  const talkVideoRef = useRef(null);
  const stopVideoRef = useRef(null);
  const [countdown, setCountdown] = useState(0);
  const [roomServices, setRoomServices] = useState({
    'en-US': [],
    'ja-JP': [],
    'zh-TW': [],
    'ko-KR': [] // Added Korean option
  });
  const [showMessage, setShowMessage] = useState(false); // New state variable

  //const [baseURL, setBaseURL] = useState('');
  // const [isTalking, setIsTalking] = useState(false); // Renamed here
  const [city, setCity] = useState('');


  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [serviceToConfirm, setServiceToConfirm] = useState(null);


  // Add the `confirmServiceRequest` function to handle setting the service and quantity to confirm.
  const confirmServiceRequest = (service, qty) => {
    setServiceToConfirm({ ...service, qty });
    setShowConfirmationModal(true);
  };

  // Modify `handleConfirmRequest` to include the quantity.
  const handleConfirmRequest = async () => {
    if (serviceToConfirm) {
      await handleServiceRequest(serviceToConfirm, serviceToConfirm.qty);
      setShowConfirmationModal(false);
    }
  };


  const modalTitleLabels = {
    'en-US': 'Are you sure?',
    'ja-JP': 'よろしいですか？',
    'zh-TW': '你確定嗎？',
    'ko-KR': '확실합니까?'
  };

  const scrollMessageLabels = {
    'en-US': (count) => `scroll for more`,
    'ja-JP': (count) => `スクロールしてください`,
    'zh-TW': (count) => `滾動查看更多`,
    'ko-KR': (count) => `스크롤하세요`
  };

  const modalScrollMessageLabels = {
    'en-US': 'Scroll for more',
    'ja-JP': 'スクロールしてください',
    'zh-TW': '滾動查看更多',
    'ko-KR': '스크롤하세요'
  }


  const [darkScreen, setDarkScreen] = useState(false);

  const toggleDarkScreen = async () => {
    if (darkScreen) {
      window.location.reload();
    } else {
      try {
        await axios.post(config.muteSpeak, { value: 0 });
        setDarkScreen(true);
      } catch (error) {
        console.error('Error setting volume:', error);
      }
    }
  };


  useEffect(() => {
    const muteOnLoad = async () => {
      try {
        await axios.post(config.muteSpeak, { value: 85 });
      } catch (error) {
        console.error('Error setting volume on load:', error);
      }
    };
    muteOnLoad();
  }, []);


  // const talkVideoURL = `${config.baseURL}/talk3.webm`;
  // const stopVideoURL = `${config.baseURL}/not_talk2.webm`;

  const talkVideoURL = "https://media.dtix.tw/hotel_2222/1715242248437.webm";
  const stopVideoURL = "https://media.dtix.tw/hotel_2222/1715242356675.webm";


  // Dictionary for TalkMessages in different languages
  const TalkMessages = {
    'ja-JP': '音声Q&Aは本ホテルに関する情報にのみ回答し、その他の質問は参考情報としてご利用ください',
    'zh-TW': '語音問答只針對有關本飯店的訊息回應, 其他非關本飯店的問題,只供參考',
    'en-US': 'Voice Q&A only responds to information related to this hotel, other questions not related to this hotel are for reference only',
    'ko-KR': '음성 Q&A는 이 호텔과 관련된 정보에만 응답하며, 이 호텔과 관련 없는 질문은 참고용입니다'
  };


  const readyToUse = useMemo(() => ({
    "en-US": "For hotel information, please press the button to answer, please see the top left corner of the screen for the WiFi password, for room service please press the room service button.",
    "ja-JP": "ホテルの情報は、ボタンを押して応答してください。WiFiのパスワードは画面左上に表示されています。ルームサービスをご希望の場合はルームサービスボタンを押してください。",
    "zh-TW": "旅館訊息請按鈕回答, Wifi 密碼請見畫面左上角, 如需客房服務, 請按客房服務按鈕",
    "ko-KR": "호텔 정보는 버튼을 눌러 응답하십시오. WiFi 비밀번호는 화면 왼쪽 상단을 참조하십시오. 객실 서비스가 필요하시면 객실 서비스 버튼을 누르십시오."
  }), []);



  useEffect(() => {
    let timer = null;
    if (countdown > 0) {
      timer = setTimeout(() => {
        setCountdown(countdown - 1);
      }, 1000);
    } else if (countdown === 0) {
      setInputText('');
      setResponseText('');
    }
    return () => clearTimeout(timer);
  }, [countdown]);

  const roomServiceLabels = {
    'en-US': 'Room Service',
    'ja-JP': 'ルームサービス',
    'zh-TW': '客房服務',
    'ko-KR': '룸 서비스' // Added Korean option
  };


  const requestsInProgressLabels = {
    'en-US': (count) => `Requests In Progress - Total ${count} items`,
    'ja-JP': (count) => `進行中のリクエスト - 合計 ${count} アイテム`,
    'zh-TW': (count) => `進行中的請求 - 總共 ${count} 項`,
    'ko-KR': (count) => `진행 중인 요청 - 총 ${count} 개`
  };



  const [accessToken, setAccessToken] = useState('');

  useEffect(() => {
    const fetchToken = async () => {
      try {
        const response = await axios.post(config.tokenUrl, {
          grant_type: 'password',
          username: 'admin',
          password: 'datavan'
        }, {
          headers: {
            'Content-Type': 'application/json; charset=utf-8'
          }
        });
        const token = response.data.access_token;
        setAccessToken(token);
        //console.log('Access Token:', token);
      } catch (error) {
        console.error('Error fetching access token:', error);
      }
    };
    fetchToken();
  }, []);



  const mapServices = (language) => {
    return roomServices[language].map((service, index) => {
      const serviceJa = roomServices['ja-JP'][index];
      return {
        ...service,
        name_ja: serviceJa ? serviceJa.name : ''
      };
    });
  };





  const fetchServices = useCallback(async () => {
    if (inputRoomNumber) {
      try {
        const response = await axios.post(config.servicesUrl, {
          command: "get_services",
          room: inputRoomNumber
        });

        const noonToday = moment.tz("Asia/Tokyo").set({ hour: 12, minute: 0, second: 0, millisecond: 0 });
        const noonYesterday = noonToday.clone().subtract(1, 'day');

        const sortedServices = response.data
          .filter(service => moment(service.request_timestamp).tz("Asia/Tokyo").isAfter(noonYesterday)) // Filter items after noon yesterday
          .sort((a, b) => {
            if (a.cancelled && !b.cancelled) return 1;
            if (!a.cancelled && b.cancelled) return -1;
            return new Date(b.request_timestamp) - new Date(a.request_timestamp); // Ensure correct timestamp field
          })
          .map(service => {
            return {
              ...service,
              name: service.name.replace(/\(.*?\)/g, '').trim()
            };
          })
          .filter(service => !service.checked); // Filter services with "checked" false

        setFetchedServices(sortedServices);
        //console.log('Services fetched and filtered:', JSON.stringify(sortedServices));

        const responseTwo = await axios.post(config.servicesUrl, {
          command: "get_settings",
          hotel_id: "2222"
        });
        //console.log('Additional data fetched:', JSON.stringify(responseTwo.data));

        if (responseTwo.data && responseTwo.data.services) {
          setRoomServices(responseTwo.data.services);
        }

        if (responseTwo.data && responseTwo.data.questions) {
          setMultilingualQuestions(responseTwo.data.questions);
        }

        if (responseTwo.data && responseTwo.data.wifi) {
          const wifiData = responseTwo.data.wifi;
          let wifiDetails = null;

          Object.keys(wifiData).forEach(wifiName => {
            wifiData[wifiName].forEach(detail => {
              if (detail.rooms === inputRoomNumber) {
                wifiDetails = { name: wifiName, password: detail.password };
              }
            });
          });

          if (wifiDetails) {
            //console.log(`WiFi ID: ${wifiDetails.name}, Password: ${wifiDetails.password}`);
            setWifiId(wifiDetails.name);
            setWifiPassword(wifiDetails.password);
          } else {
            //console.log('No WiFi details found for the given room number.');
          }
        }

        if (responseTwo.data && responseTwo.data.city) {
          setCity(responseTwo.data.city);
        }

      } catch (error) {
        console.error('Failed to fetch services:', error);
        setTimeout(function () {
          window.location.reload();
        }, 5000);
      }
    }
  }, [inputRoomNumber]);




  useEffect(() => {
    fetchServices();
  }, [fetchServices]);



  useEffect(() => {
    fetchServices();
    const intervalId = setInterval(fetchServices, 30000);
    return () => clearInterval(intervalId);
  }, [inputRoomNumber, fetchServices]);

  // Modify `handleServiceRequest` to accept the qty.
  const handleServiceRequest = async (service, qty) => {
    const currentTimeInJapan = moment().tz("Asia/Tokyo");
    const noonTodayInJapan = moment().tz("Asia/Tokyo").set({ hour: 12, minute: 0, second: 0, millisecond: 0 });
    const billDate = currentTimeInJapan.isBefore(noonTodayInJapan)
      ? currentTimeInJapan.clone().subtract(1, 'day').format('YYYY-MM-DD')
      : currentTimeInJapan.format('YYYY-MM-DD');

    try {
      const response = await axios.post(config.servicesUrl, {
        name: service.name,
        name_ja: service.name_ja,
        price: service.price,
        room: inputRoomNumber,
        command: "new_service",
        qty: qty,
        bill_date: billDate
      });
      console.log('Service requested:', response.data.length);
      setShowModal(false);
      setTimeout(() => {
        fetchServices();
      }, 1000);
    } catch (error) {
      console.error('Failed to request service:', error);
      setTimeout(function () {
        window.location.reload();
      }, 5000);
    }
  };



  const [multilingualQuestions, setMultilingualQuestions] = useState({
    'en-US': [],
    'ja-JP': [],
    'zh-TW': [],
    'ko-KR': [] // Added Korean option
  });



  const buttonLabels = {
    'ja-JP': "以下の製品を購入し、部屋に届けます。数量を選択してください",
    'zh-TW': "您將購買以下產品,並送至您的房間,請選擇數量",
    'en-US': "You will purchase the following items and deliver them to your room, please select the quantity",
    'ko-KR': "다음 제품을 구매하여 방으로 배달합니다. 수량을 선택하십시오"
  };

  const chunkArray = (arr, size) => {
    const result = [];
    for (let i = 0; i < arr.length; i += size) {
      result.push(arr.slice(i, i + size));
    }
    return result;
  };

  const questionChunks = chunkArray(multilingualQuestions[language], 6);




  const verifyPassword = () => {
    const correctPassword = 'abc123';
    if (password === correctPassword) {
      setIsPasswordVerified(true);
      alert('Password Verified. You can now save the room number.');
    } else {
      alert('Incorrect Password!');
      setIsPasswordVerified(false);
    }
  };

  const saveRoomNumber = () => {
    Cookies.set('roomNumber', inputRoomNumber, { expires: 3650 });
    alert('Room number saved!');
    window.location.reload();
  };

  const togglePasswordInput = () => {
    setShowPasswordInput(!showPasswordInput);
  };

  useEffect(() => {
    const savedRoomNumber = Cookies.get('roomNumber');
    if (savedRoomNumber) {
      setInputRoomNumber(savedRoomNumber);
    }
  }, []);

  useEffect(() => {
    //console.log('Button disabled state is:', isButtonDisabled);
  }, [isButtonDisabled]);

  useEffect(() => {
    inputTextRef.current = inputText;
  }, [inputText]);


  const speak = useCallback(async (textToSpeak, rate = 1.0, pitch = 1) => {
    if (!textToSpeak.trim()) return;

    setIsButtonDisabled(true); // Disable the button when speech starts

    const utteranceId = uuidv4();  // Generate a unique identifier

    try {
      await axios.post(config.textToSpeechUrl, {
        func: 'init',
        lang: language,
        speech_rate: rate
      }, {
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${accessToken}`
        }
      });

      const response = await axios.post(config.textToSpeechUrl, {
        func: 'speak',
        mode: 'flash',
        volume: '1.0',
        text: textToSpeak,
        utterance_id: utteranceId
      }, {
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${accessToken}`
        }
      });

      if (response.data.detail === 'success') {
        setIsSpeaking(true);
        setSessionStarted(true);
        setTalking(true);
        setThinking(false);

        const checkSpeechStatus = async () => {
          try {
            const progressResponse = await axios.post(config.textToSpeechUrl, {
              func: "progress",
              utterance_id: utteranceId
            }, {
              headers: {
                'Content-Type': 'application/json; charset=utf-8',
                Authorization: `Bearer ${accessToken}`
              }
            });

            if (progressResponse.data.state === 'done') {
              //console.log('Speech session finished');
              setTalking(false);
              setIsButtonDisabled(false);
              setCountdown(20);
              setShowMessage(false); // Hide the message when the speech is done
            } else {
              setTimeout(checkSpeechStatus, 1000); // Check again after 1 second
              // //console.log('progressResponse.data.state', progressResponse.data.state);
            }
          } catch (error) {
            console.error('Error checking speech status:', error);
          }
        };

        checkSpeechStatus();
      }
    } catch (error) {
      console.error('Error performing text to speech:', error);
    }
  }, [language, accessToken]);


  const processText = useCallback((text) => {
    const parsedData = JSON.parse(`[${text.replace(/}\s*{/g, '},{')}]`);
    let extractedContent = parsedData.map(item => item.choices[0].delta.content).join("");

    const contentLabels = {
      'en-US': {
        hotelInfo: "<span style='font-size:10px; color:grey;'>Hotel Info</span>",
        aiConversation: "<span style='font-size:10px; color:red;'>AI Conversation - mistakes might be included, check important info.</span>"
      },
      'ja-JP': {
        hotelInfo: "<span style='font-size:10px; color:grey;'>ホテル情報</span>",
        aiConversation: "<span style='font-size:10px; color:red;'>AI対話 - 間違える可能性がありますので、重要な情報を確認してください。</span>"
      },
      'zh-TW': {
        hotelInfo: "<span style='font-size:10px; color:grey;'>飯店資訊</span>",
        aiConversation: "<span style='font-size:10px; color:red;'>AI對談 - 可能會出錯，請檢查重要信息。</span>"
      },
      'ko-KR': {
        hotelInfo: "<span style='font-size:10px; color:grey;'>호텔 정보</span>",
        aiConversation: "<span style='font-size:10px; color:red;'>AI 대화 - 실수가 있을 수 있으니 중요한 정보를 확인하세요.</span>"
      }
    };



    extractedContent = extractedContent
      .replace(/&/g, `${contentLabels[language].hotelInfo}`)
      .replace(/\*/g, `${contentLabels[language].aiConversation}`);

    if (extractedContent.includes(contentLabels[language].hotelInfo)) {
      setContentSource('hotelInfo');
    } else if (extractedContent.includes(contentLabels[language].aiConversation)) {
      setContentSource('aiConversation');
    }

    const cleanedContent = extractedContent
      .replace(new RegExp(`${contentLabels[language].hotelInfo}<br/>`, 'g'), '')
      .replace(new RegExp(`${contentLabels[language].aiConversation}<br/>`, 'g'), '')
      .replace(/<br\/>/g, ' ');

    const sentences = cleanedContent.split(/[.!?，\uFF1F\uFF5E\u3002\n\uFF01]+/).filter(Boolean);
    const trimmedSentences = sentences.map(sentence => sentence.trim());
    var sentence_string = trimmedSentences.join(', ') + '.';

    // Remove the first occurrence of ', ' from the resulting string
    sentence_string = sentence_string.replace(', ', '');
    // Replace </span> with </span><br/>
    sentence_string = sentence_string.replace(/<\/span>/g, '</span><br/>');


    //console.log('sentences', sentences);
    //console.log('sentence_string', sentence_string);
    // //console.log('extractedContent', extractedContent)
    // setInitialQueueSize(sentences.length);
    setQueue(sentences);
    // setResponseText(extractedContent);
    setResponseText(sentence_string);
    if (sentences.length > 0) setTalking(true);
  }, [language]);


  useEffect(() => {
    speak(readyToUse[language]); // Speak the readyToUse text when the page loads
  }, [language, speak, readyToUse]);


  const handleSubmit = useCallback(async (event) => {
    const audio = new Audio(sentSound);
    audio.play();

    setCountdown(null);
    setIsButtonDisabled(true);
    //console.log('Submit initiated, button should be disabled shortly.');
    console.log(isButtonDisabled);

    setThinking(true);
    console.log(thinking);

    if (event && event.preventDefault) {
      event.preventDefault();
    }

    const messages = {
      'en-US': "I did not hear anything, please press Mic button and talk again",
      'ja-JP': "何も聞こえませんでした。もう一度マイクボタンを押して話してください",
      'zh-TW': "我沒有聽到任何聲音，請按麥克風按鈕再說一遍",
      'ko-KR': "아무것도 들리지 않았습니다. 마이크 버튼을 누르고 다시 말해주세요"
    };

    // Introduce a short delay before performing the validation check
    setTimeout(async () => {
      if (inputTextRef.current.length <= 3) {
        console.log('not receiving valid string');
        setResponseText(messages[language]);
        setIsButtonDisabled(false); // Re-enable the button if the input is not valid
        setThinking(false);
        return;
      }

      const requestData = {
        talk: inputTextRef.current,
        name: "room235"
      };

      console.log(inputRoomNumber);
      console.log(requestData);

      try {
        const response = await fetch(config.streamUrl, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(requestData),
        });
        console.log('submit');

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const reader = response.body.getReader();
        let buffer = '';

        const processStream = async ({ done, value }) => {
          if (done) {
            processText(buffer);
            const sentences = JSON.parse(`[${buffer.replace(/}\s*{/g, '},{')}]`).map(item => item.choices[0].delta.content).join("");
            let sentence_string = sentences.split(/[.!?，\uFF1F\uFF5E\u3002\n\uFF01]+/).filter(Boolean).join(',') + '.';

            // Remove the first character
            if (sentence_string.length > 0) {
              sentence_string = sentence_string.slice(1);
            }

            //console.log("sentence_string1", sentence_string);
            speak(sentence_string); // Use sentence_string for text-to-speech
            return;
          }
          const textChunk = new TextDecoder("utf-8").decode(value);
          buffer += textChunk;
          return reader.read().then(processStream);
        };

        reader.read().then(processStream);
      } catch (error) {
        console.error('Failed to fetch:', error);
        setResponseText('RAG Failed to fetch data at hotel api post RAG');
        setTimeout(function () {
          window.location.reload();
        }, 3000);
      }
    }, 300); // Adjust the delay as needed (300 milliseconds in this case)
  }, [inputRoomNumber, isButtonDisabled, thinking, processText, speak, language]);



  useEffect(() => {
    const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
    if (SpeechRecognition) {
      const recognition = new SpeechRecognition();
      recognition.lang = language;
      recognition.interimResults = true;
      recognition.maxAlternatives = 1;

      recognition.onresult = event => {
        const transcript = event.results[0][0].transcript;
        setInputText(transcript);
      };

      recognition.onstart = () => setIsListening(true);

      recognition.onend = () => {
        setIsListening(false);
        //console.log('inputText at end:', inputTextRef.current);
        handleSubmit(inputTextRef.current);
      };

      recognition.onerror = event => {
        console.error('Recognition error:', event.error);
        // alert('Speech recognition error occurred. The page will reload in 20 seconds.');
        setTimeout(() => {
          window.location.reload();
        }, 20000);
      };

      recognitionRef.current = recognition;
    } else {
      console.warn("This browser doesn't support speech recognition.");
    }
  }, [handleSubmit, inputText, language]);



  // const startListening = () => {
  //   setInputText('');
  //   setResponseText('');
  //   recognitionRef.current?.start();
  //   setIsButtonDisabled(true);
  //   setShowMessage(true); // Show the message when the button is clicked
  // };


  const startListening = () => {
    setInputText('');
    setResponseText('');
    recognitionRef.current?.start();
    setIsButtonDisabled(true);
    setShowMessage(true); // Show the message when the button is clicked
  };

  // const handleLanguageChange = (newLanguage) => {
  //   setLanguage(newLanguage);
  //   if (isListening) {
  //     recognitionRef.current?.stop();
  //   }
  // };

  const handleLanguageChange = (newLanguage) => {
    setLanguage(newLanguage);
    if (isListening) {
      recognitionRef.current?.stop();
    }
    speak(readyToUse[newLanguage]); // Speak the readyToUse text when the language button is clicked
    setCountdown(null);
  };



  const handleInputChange = event => {
    setInputText(event.target.value);
  };


  useEffect(() => {
    if (!isSpeaking && queue.length > 0) {
      const nextSentence = queue.shift();
      speak(nextSentence);
      //console.log('nextSentence', nextSentence)
    }
  }, [queue, isSpeaking, speak, sessionStarted]);



  useEffect(() => {
    const currentTalkVideo = talkVideoRef.current;
    const currentStopVideo = stopVideoRef.current;

    if (currentTalkVideo && currentStopVideo) {
      currentTalkVideo.play();
      currentStopVideo.play();
    }

    const handleTimeUpdate = () => {
      const buffer = 0.5;
      if (currentTalkVideo.currentTime > currentTalkVideo.duration - buffer) {
        currentTalkVideo.currentTime = 0;
        currentTalkVideo.play();
      }
      if (currentStopVideo.currentTime > currentStopVideo.duration - buffer) {
        currentStopVideo.currentTime = 0;
        currentStopVideo.play();
      }
    };

    if (talking) {
      currentTalkVideo.play();
    } else {
      currentStopVideo.play();
    }

    currentTalkVideo.addEventListener('timeupdate', handleTimeUpdate);
    currentStopVideo.addEventListener('timeupdate', handleTimeUpdate);

    return () => {
      if (currentTalkVideo) {
        currentTalkVideo.removeEventListener('timeupdate', handleTimeUpdate);
      }
      if (currentStopVideo) {
        currentStopVideo.removeEventListener('timeupdate', handleTimeUpdate);
      }
    };
  }, [talking]);

  useEffect(() => {
    setResponseText(readyToUse[language]); // Update responseText with the readyToUse text on page load
    speak(readyToUse[language]); // Speak the readyToUse text when the page loads
  }, [language, speak, readyToUse]);


  return (
    <div className="App flex justify-around items-center">
      <div className='service-aiRobot flex-1' style={{ textAlign: 'center', marginTop: '20px' }}>


        <div className="wifi-password-info" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '20px', marginBottom: '20px' }}>
          <div style={{ width: '100px' }}>
            <Weather city={city} />
          </div>

          <div className="wifi-info" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '130px' }}>
            <FontAwesomeIcon icon={faWifi} className="text-4xl" style={{ marginBottom: '8px', color: 'rgb(96, 138, 52)' }} />
            <span style={{ fontSize: '20px', fontWeight: 'bold', color: '#007bff' }}>{wifiId}</span>
          </div>

          <div className="password-info" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '110px' }}>
            <FontAwesomeIcon icon={faKey} className="text-4xl" style={{ marginBottom: '8px', color: 'rgb(96, 138, 52)' }} />
            <span style={{ fontSize: '20px', fontWeight: 'bold', color: '#007bff' }}>{wifiPassword}</span>
          </div>

          <div style={{ display: 'flex', alignItems: 'center' }}>
            <button
              onClick={toggleDarkScreen}
              className='text-white p-6 rounded-full shadow-lg'
              style={{ backgroundColor: '#444444', width: '70px', height: '70px', fontSize: '2.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
              <FontAwesomeIcon icon={faVolumeMute} />
            </button>
          </div>

        </div>
        <div className="video-container">
          <video
            ref={talkVideoRef}
            src={talkVideoURL}
            className={`w-full h-h-1200 ${talking ? 'opacity-100' : 'opacity-0'}`}
            loop
            muted
            playsInline
            preload="auto"
          />
          <video
            ref={stopVideoRef}
            src={stopVideoURL}
            className={`w-full h-h-1200 absolute top-0 ${talking ? 'opacity-0' : 'opacity-100'}`}
            loop
            muted
            playsInline
            preload="auto"
          />
        </div>
        {fetchedServices.length > 0 && (
          <div style={{
            position: 'absolute',
            top: '155px',
            left: '20px',
            width: '20rem',
            height: '280px',
            overflowY: 'auto',
            margin: '0'
          }}>
            <Card>
              <Card.Header className="flashing-header bg-warning">
                <span style={{ color: 'black' }}>
                  {requestsInProgressLabels[language](fetchedServices.length)}
                </span>
              </Card.Header>

              <ListGroup variant="flush">
                {fetchedServices.length > 5 && (
                  <ListGroup.Item style={{ textAlign: 'center', color: 'red', fontWeight: 'bold' }}>
                    {scrollMessageLabels[language](fetchedServices.length)}
                  </ListGroup.Item>
                )}
                {fetchedServices.map((service) => (
                  <ListGroup.Item key={service._id.$oid} style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    color: service.cancelled ? 'grey' : 'inherit',
                    position: 'relative'
                  }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                      <div style={{ textAlign: 'left' }}>
                        <span style={{ marginRight: '10px', fontSize: '8px', color: 'grey' }}>
                          {moment(service.request_timestamp).tz('Asia/Tokyo').format('DD-HH:mm')}
                        </span>
                        <span style={{
                          fontSize: '15px',
                          color: service.cancelled ? 'grey' : '#007bff',
                          marginRight: '5px',
                          fontWeight: 'bold'
                        }}>
                          {service.name}
                        </span>
                        <span style={{ fontSize: '15px', marginRight: '5px', fontWeight: 'bold' }}>
                          x {service.qty}
                        </span>
                      </div>
                      <div style={{ textAlign: 'right', marginLeft: 'auto' }}>
                        <span style={{ fontSize: '15px' }}>
                          ¥{service.price * service.qty}
                        </span>
                      </div>
                    </div>
                  </ListGroup.Item>
                ))}
              </ListGroup>


            </Card>
          </div>
        )}


        <div className='recording-and-res-area flex justify-between items-center gap-5'>
          <div className='qa-textarea w-full px-3 py-3'>
            <div
              dangerouslySetInnerHTML={{ __html: responseText }}
              style={{
                width: "100%",
                height: "200px",
                fontSize: '25px',
                border: '0.5px solid #90EE90',
                borderRadius: '5px',
                whiteSpace: 'pre-wrap',
                backgroundColor: contentSource === 'hotelInfo' ? 'white' : contentSource === 'aiConversation' ? 'white' : 'white'
              }}
            />
            {/* <div style={{ minHeight: '10px', marginTop: '10px' }}>
              {countdown > 0 && (
                <div style={{ color: 'grey' }}>{countdown} sec clear</div>
              )}
            </div> */}
          </div>
        </div>
      </div>
      <div className='hotel-service-btn' style={{ width: '570px', flex: '1', padding: '2%' }}>
        <div className='room-and-translate flex justify-between items-center mb-5'>
          <div className='room-number-textarea' style={{ display: 'flex', justifyContent: 'flex-start' }}>
            <div>
              <FontAwesomeIcon icon={faHome} className="text-4xl" style={{ marginRight: '8px', color: 'rgb(96, 138, 52)' }} />
              <input
                type="text"
                value={inputRoomNumber}
                onChange={(e) => setInputRoomNumber(e.target.value)}
                placeholder="Room # is needed to proceed"
                disabled={!isPasswordVerified}
                style={{ fontSize: '30px', border: '3px solid rgba(110, 166, 52, 1)', borderRadius: '20px', padding: '10px', width: '50%' }}
              />
              {isPasswordVerified && (
                <Button onClick={saveRoomNumber}>Save</Button>
              )}
              <button onClick={togglePasswordInput} style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
                <FontAwesomeIcon icon={faPen} className="text-4xl" style={{ color: 'rgb(235 235 235)', position: 'relative', right: '60px' }} />
              </button>
              {showPasswordInput && !isPasswordVerified && (
                <>
                  <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Enter password to authorize"
                  />
                  <button onClick={verifyPassword}>Verify</button>
                </>
              )}
            </div>
          </div>


          <div className="translate-btn" style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
            {['ja-JP', 'zh-TW', 'en-US', 'ko-KR'].map((lang) => (
              <Button
                key={lang}
                style={{ width: '70px', height: '70px', borderRadius: '50%' }}
                disabled={isButtonDisabled}
                onClick={() => handleLanguageChange(lang)}
                variant={language === lang ? 'primary' : 'secondary'}
              >
                <h3 className='mb-0'>{lang === 'ja-JP' ? 'あ' : lang === 'zh-TW' ? '繁' : lang === 'en-US' ? 'En' : '한'}</h3>
              </Button>
            ))}
          </div>


        </div>

        <div className="room-service-btn" style={{ display: 'flex', justifyContent: 'flex-start', marginBottom: '10px' }}>
          <Button
            onClick={() => setShowModal(true)}
            style={{ padding: '10px', fontSize: '35px', fontWeight: 'bold', width: '100%', height: '100%', borderRadius: '20px', backgroundColor: 'rgba(96, 138, 52, 1)', border: '1px solid rgba(110, 166, 52, 1)', color: 'white' }}
            disabled={isButtonDisabled || inputRoomNumber.trim() === ''}
          >
            <span className="flashing-text">{roomServiceLabels[language]}</span>
          </Button>
          <Modal show={showModal} onHide={() => setShowModal(false)} centered size="lg">
            <Modal.Dialog style={{ maxWidth: '800px' }}>
              <Modal.Header closeButton style={{ backgroundColor: 'lightgrey' }}>
                <Modal.Title> 『{inputRoomNumber}』{buttonLabels[language]} </Modal.Title>

              </Modal.Header>
              <Modal.Body style={{ maxHeight: '550px', overflowY: 'auto' }}>
                {mapServices(language).length > 8 && (
                  <div style={{ textAlign: 'center', color: 'red', fontWeight: 'bold', marginBottom: '10px' }}>
                    {modalScrollMessageLabels[language]}
                  </div>
                )}

                {mapServices(language).map((service, index) => (
                  <div key={index} style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: '10px',
                    fontSize: '20px',
                    width: '750px'
                  }}>
                    <p style={{ margin: '0', flex: 1 }}>
                      <span style={{ color: 'blue', fontWeight: 'bold' }}>{service.name}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;¥{service.price}
                    </p>
                    {[1, 2, 3].map(qty => (
                      <Button
                        key={qty}
                        onClick={() => confirmServiceRequest(service, qty)}
                        style={{
                          marginLeft: '30px',
                          fontSize: '24px',
                          width: '90px' // Adjust the width as needed
                        }}
                      >
                        {qty}
                      </Button>
                    ))}
                  </div>
                ))}
              </Modal.Body>
            </Modal.Dialog>
          </Modal>

          <Modal show={showConfirmationModal} onHide={() => setShowConfirmationModal(false)} centered style={{ backgroundColor: 'lightgrey' }}>
            <Modal.Header closeButton>
              <Modal.Title>{modalTitleLabels[language]}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {serviceToConfirm && (
                <p style={{ fontSize: '2em' }}>
                  <span style={{ color: '#007bff', fontWeight: 'bold' }}>{serviceToConfirm.name}</span> <br />  ¥{serviceToConfirm.price} x {serviceToConfirm.qty}<br />
                  = ¥{serviceToConfirm.price * serviceToConfirm.qty}
                </p>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => setShowConfirmationModal(false)}
                style={{ width: '100px', height: '50px', fontSize: '1.5em' }}
              >
                x
              </Button>
              <Button
                variant="primary"
                onClick={handleConfirmRequest}
                style={{ width: '100px', height: '50px', fontSize: '1.5em' }}
              >
                OK
              </Button>
            </Modal.Footer>
          </Modal>
        </div>

        <form onSubmit={handleSubmit} style={{ height: '200px' }}>
          <Carousel interval={10000} indicators={false} controls={false}>
            {questionChunks.map((chunk, index) => (
              <Carousel.Item key={index}>
                <div className='question-btn' style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  gap: '10px',
                  height: '100%'
                }}>
                  <div className='w-full flex flex-wrap gap-3'>
                    {chunk.map((question, index) => (
                      <Button
                        key={index}
                        onClick={() => {
                          setInputText(question);
                          setTimeout(() => {
                            handleSubmit();
                          }, 100);
                        }}
                        disabled={inputRoomNumber.trim() === '' || isButtonDisabled}
                        style={{
                          width: '30%',
                          height: '120px',
                          borderRadius: '20px',
                          color: 'white',
                          border: '5px solid rgb(166 200 22)',
                          backgroundImage: 'linear-gradient(to bottom left, #ACD112 0%, #8DA52B 100%)'
                        }}
                      >
                        <h5 style={{ width: '100%' }}>{question}</h5>
                      </Button>
                    ))}
                  </div>
                </div>
              </Carousel.Item>
            ))}
          </Carousel>
        </form>
        <div className='recording-and-res-area flex justify-between items-center gap-5' style={{ marginTop: '30px', height: '270px' }}>
          <div className='start-recording' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '50px' }}>
              {thinking ? (
                <Spinner animation="grow" variant="success" style={{ width: '8rem', height: '8rem' }} />
              ) : (
                <Button
                  onClick={startListening}
                  disabled={isButtonDisabled || inputRoomNumber.trim() === ''}
                  style={{ width: '130px', height: '130px', borderRadius: '50%', backgroundColor: 'rgba(96, 138, 52, 1)' }}
                >
                  {isListening ? (
                    <FontAwesomeIcon icon={faCommentDots} className="text-7xl" style={{ color: 'white', borderRadius: '50%' }} />
                  ) : (
                    <FontAwesomeIcon icon={faMicrophone} className="text-7xl" style={{ color: 'white' }} />
                  )}
                </Button>
              )}
            </div>


          </div>


          <div className='qa-textarea w-full px-3 py-3' style={{ marginTop: '50px' }}>
            <textarea
              value={inputText}
              onChange={handleInputChange}
              style={{ width: '400px', fontSize: '25px', fontWeight: 'bold', border: 'none', outline: 'none' }}
              readOnly
            />
            {showMessage && (
              <div style={{ marginTop: '10px', color: 'red', fontSize: '15px', textAlign: 'center' }}>
                {TalkMessages[language]}
              </div>
            )}
          </div>



        </div>
        <div className='fixed bottom-10 right-10 z-50'>
          <button
            onClick={() => window.location.reload()}
            className='bg-gray-800 text-white p-2 rounded-full shadow-lg'
          >
            <FontAwesomeIcon icon={faSync} size="lg" />
          </button>
        </div>
      </div>



      {darkScreen && (
        <div className="dark-screen" onClick={toggleDarkScreen}></div>
      )}


      <div className='fixed bottom-10 right-10 z-50'>
        <button
          onClick={() => window.location.reload()}
          className='bg-gray-300 text-white p-2 rounded-full shadow-lg'
        >
          <FontAwesomeIcon icon={faSync} size="lg" />
        </button>
      </div>


    </div >
  );
}

export default App;